import React, { useEffect } from 'react'
import Gallery from 'react-photo-gallery';
import { photos } from "./photos"

function Carousel() {

    const BasicRows = () => <Gallery photos = {photos} />
   
    return (
        
        <BasicRows/>
            
    );
}

export default Carousel;
