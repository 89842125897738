import React, { useEffect } from 'react'
import { ContactUs } from './mailForm';
import Carousel from "./Carousel"
import logo from './img/logotrans.png';
import logo2 from './img/logotrans.jpg'
import intro from "./img/1.jpg"
import circle from "./img/2.jpg"
import eng from "./img/eng.png";
import gre from "./img/gre.jpg";
import ham from "./img/hamburger.png"
import { IoIosBoat, IoIosAirplane, IoIosBusiness, IoIosPhonePortrait, IoIosMail } from "react-icons/io"
import { FaMapMarkerAlt, FaWhatsapp, FaViber, FaFacebook } from "react-icons/fa";
import { Helmet } from "react-helmet"



function App() {
  
 
  return (
    <div className=" ">
      <Helmet>
        <title>Villa Iris Kythira</title>
        <meta name="description" content="Accommodation in Kythira Island. Explore Kythira staying in our sea view house!"></meta>
        
      </Helmet>
      <header className="font-nanumMyeongjo bg-white px-5 mb-5 grid grid-cols-7 md:grid-cols-6 align-middle z-50 sticky top-0">
        <img className="h-24 lg:h-28 xl:h-32 col-start-1 col-span-2" src={logo} alt="logo" />
        <div className="flex flex-col justify-start  col-start-5 md:col-start-4 col-span-3  space-y-3 mr-2 ">
          <p className="text-xs lg:text-lg   text-right lg:mb-2 " >
            <IoIosMail className="md:text-2xl text-lg text-purple-800 inline-block mr-2 " />
            villairiskythira@gmail.com 
          </p>
          <p className="text-xs lg:text-lg xl:text-xl  text-right " >
            <FaViber className="md:text-2xl text-lg text-purple-800 inline-block mr-2 " />
            <FaWhatsapp className="md:text-2xl text-lg text-green-500 inline-block mr-2 " />
            +306974431170
          </p>
          <p className="text-xs lg:text-lg xl:text-xl  text-right ">
            <FaMapMarkerAlt className="md:text-2xl text-lg text-purple-800 inline-block mr-2 " />
            Strapodi, Kythira
          </p>
          {/*<p className="text-xs lg:text-lg xl:text-xl  text-left " >
            <FaFacebook className="md:text-2xl text-lg text-blue-700 inline-block mr-2 " />
            Villa Iris
          </p>*/} 
        </div>
        { /* <div className=" flex flex-col col-start-6 md:col-start-6 col-span-2 md:col-span-1 space-y-3 mr-2 ">

          <div className="flex space-x-1 justify-end">

            <img alt="link for greek language" src={gre} className="h-3 lg:h-4 " />
           <img alt="link for english language" src={eng} className="h-3 lg:h-4" />
          </div>
        </div>*/}
        {/*
          <menu className="">
            <img className="h-8" alt="link for the menu" src={ham} />
          </menu>
        */} 
      </header>
      <div className= " h-72 relative " >
        <img className="absolute z-0 shadow-2xl w-full h-60 md:h-80 sm:object-cover object-center lg:h-96 "  alt="photo from the house with the view" src={intro} />
      </div>
      <main className=" overflow-x-hidden grid grid-col-2bg-white  h-full mb-10 md:mt-32 lg:mt-40 md:mx-10">
        <div className="col-start-1 ml-5 md:mr-5  ">
          <p className="font-nanumMyeongjo tracking-wide mt-6 mr-2 md:text-lg lg:text-2xl md:text-base w-52 md:w-4/5 flex flex-col align-center justify-center ">
           
            <p className='tracking-widest text-center text-2xl lg:text-4xl  ' >VILLA IRIS</p>
            <br />
            Villa Iris is located in the village of Strapodi and has spectacular views of the
            famous Venetian Castle that crowns Kapsali Bay. On a clear day, you can see as far
            as Antikythira with the beautiful Mediterranean blue stretching before you. Kythira
            is an island of magic, of history and folklore, and we hope our home offers
            you the perfect stay to explore!

          </p>
          
        </div>
        <div className="col-start-2 mr-5 md:ml-5 flex align-center justify-center ">
          <img className=' w-full md:w-3/4 justify-self-center  object-center object-cover' alt='photo from the view of the house' src={circle}/>
        </div>
        
      </main>
      <div className='w-full flex flex-col '>
        <div className='font-nanumMyeongjo text-lg self-center w-3/4 mb-10 mt-4 flex justify-around border-t-2 border-b-2 border-purple-700'>
          <div className=' text-center  ml-10 mr-10'>
            <p>GUESTS</p>
            <p>8</p>
          </div>
          <div className='mr-10 text-center'>
            <p>BEDROOMS</p>
            <p>4</p>
          </div>
          <div className=' text-center mr-10'>
            <p>BATHROOMS</p>
            <p>2</p>
          </div>
        </div>
        <div className="font-nanumMyeongjo self-center w-3/4 flex overflow-x-hidden text-sm  mt-10 ml-5 md:text-lg md:mx-28 ">
          <div className="  mb-4 pr-4 w-1/2 ">
            <h4 className='text-center text-lg mb-3 border-b-2 border-green-200 md:text-xl' >
              LEVEL 1
            </h4>
            <p className='leading-loose tracking-wide text-center text-base md:text-lg'>
              Luxury Miele Kitchen <br/>
              Dining Area <br />
              Living Room Overlooking The Sea <br />
              Two Bedrooms <br />
              Bathroom With Jacuzzi<br />
              Big Office
            </p>
          </div>
          <div className="mb-4 pl-4 w-1/2">
            <h4 className='text-center text-lg mb-3 border-b-2 border-green-200 md:text-xl'>LEVEL 2</h4>
            <p className='tracking-wide leading-loose text-center text-base md:text-lg'>
              Two Bedrooms<br/>
              Big Balcony Sea View <br />
              Bathroom 
            </p>

          </div>
        </div>
      </div>
      
      <div className="overflow-x-hidden flex justify-center">
        <Carousel />
      </div>
      <br/>
      <div className='font-nanumMyeongjo flex flex-col md:text-lg '>
        <div className='self-center w-3/4 flex flex-col' >
          <h1 className='tracking-widest font-medium w-2/4 pb-1 mb-2 self-center text-center border-b-2 border-green-200 '>THE SPACE</h1>
          <p className='tracking-wide mb-6'>
            Our home has 4 bedrooms, 2 bathrooms both with toilets and showers,
            one with a jacuzzi. We have washing machine and drying facilities.
            A large open-spaced living room that connects with the kitchen and a
            fireplace in between for the colder months. It is cozy, loved and spacious space,
            which we are sure you will feel at home in.<br />
          </p>
        </div>
        <div className='self-center w-3/4 flex flex-col'>
          <h1 className='tracking-widest font-medium w-2/4 pb-1 mb-2 self-center text-center border-b-2 border-green-200 uppercase '>Guest access</h1>
          <p className='tracking-wide mb-6'>
            You have access to the entire beautiful
            home, the large balcony with uninterrupted views
            of Hydra (a must trip on the glass-bottom boat), and our manicured garden
            – with olive trees, lemon trees and a glorious parisi tree , which you
            are welcome to explore.
          </p>
        </div>
        <div className='self-center w-3/4 flex flex-col'>
          <h1 className='tracking-widest font-medium w-2/4 pb-1 mb-2 self-center text-center border-b-2 border-green-200 uppercase '>Guest Interaction</h1>
          <p className='tracking-wide mb-6'>
            You will be greeted upon arrival and we live not too far
            away if there is anything you want and need.
            I would be happy to share with you the secrets
            of the island and offer any recommendations.
            I am local here and have lived here all my life.
          </p>
        </div>
        <div className='self-center w-3/4 flex flex-col'>
          <h1 className='tracking-widest font-medium w-2/4 pb-1 mb-2 self-center text-center border-b-2 border-green-200 uppercase '>Neighborhood overview<br /></h1>
          <p className='tracking-wide mb-6'>
            Strapodi is the highest village located in the south
            and considered some of the most desirable property Kythira has to offer.
            Its views are unmatched. It’s just 3 kms from both Hora (the capital) and Livadi,
            which are two main villages with shops and wonderful restaurants serving traditional Kytherian food.
            On Wednesday afternoon there is a street market in Livadi and you can purchase amazing local products.
            Kytherian Honey is a must! Beaches are close by; Kapsali beach has umbrellas, water sports, cafes,
            restaurants and bars. Feloti is a secluded beach for those adventurous enough to drive along a
            cliff edge to get to! There are many others, feel free to ask.
          </p>
        </div>
        <div className='self-center w-3/4 flex flex-col'>
          <h1 className='tracking-widest font-medium w-2/4 pb-1 mb-2 self-center text-center border-b-2 border-green-200 '>GETTING AROUND</h1>
          <p className='tracking-wide mb-6'>
            Hiring a car in Kythira is a must.
            There is an excellent hire car company called Drakakis rent-a-car and
            I can help you with organizing that at the best price. Kythira also has taxi services, but no buses.
            There are great walking routes to explore the island. 
          </p>
        </div>
      </div>
      <br />
      <br />
      <div className="border-purple-800 border-t-2">
        <h6 className="my-2 mr-5  text-sm text-right"><b>Created By:</b> Eleni Fatsea</h6>
      </div>
      
      
    </div>
  );
}

export default App;
