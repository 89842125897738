
export const photos = [
    
    
    {
        src: "/img/4.jpg",
        width: 4,
        height: 3,
        loading: "lazy"
    },
   
    {
        src: "/img/6.jpg",
        width: 3,
        height: 4,
        loading: "lazy"
    },
    {
        src: "/img/11.jpg",
        width: 4,
        height: 3,
        loading: "lazy"
    },
    {
        src: "/img/10.jpg",
        width: 4,
        height: 3,
        loading: "lazy"
    },
    {
        src: "/img/9.jpg",
        width: 4,
        height: 3,
        loading: "lazy"
    },
    {
        src: "/img/12.jpg",
        width: 4,
        height: 3,
        loading: "lazy"
    },
    {
        src: "/img/13.jpg",
        width: 4,
        height: 3,
        loading: "lazy"
    },
    {
        src: "/img/14.jpg",
        width: 4,
        height: 3,
        loading: "lazy"
    },
    {
        src: "/img/15.jpg",
        width: 4,
        height: 3,
        loading: "lazy"
    },
    {
        src: "/img/16.jpg",
        width: 4,
        height: 3,
        loading: "lazy"
    },
    {
        src: "/img/17.jpg",
        width: 4,
        height: 3,
        loading: "lazy"
    },
    
    {
        src: "/img/19.jpg",
        width: 4,
        height: 3,
        loading: "lazy"
    },
    {
        src: "/img/18.jpg",
        width: 3,
        height: 4,
        loading: "lazy"
    },
    {
        src: "/img/20.jpg",
        width: 4,
        height: 3,
        loading: "lazy"
    },
    {
        src: "/img/7.jpg",
        width: 4,
        height: 3,
        loading: "lazy"
    },
    {
        src: "/img/8.jpg",
        width: 4,
        height: 3,
        loading: "lazy"
    },
    {
        src: "/img/b.jpg",
        width: 4,
        height: 3,
        loading: "lazy"
    },
    {
        src: "/img/a.jpg",
        width: 4,
        height: 3,
        loading: "lazy"
    },
];